import axios from 'axios'
import store from '../store'
axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

class TAPIClient {
  async _request (method, url, data) {

    let host = process.env.VUE_APP_URL
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:32112/'
    } else {
      host = `https://backend.${host}/`
    }
     */

    if (host[host.length-1] !== '/') host += '/'
    url = host + url
    const tokenKey = `${process.env.VUE_APP_COOKIE_PREFIX}-Token`
    const token = store.getters['auth/token']

    try {
      const headers = {
        accept: 'application/json',
        'content-type': 'application/json',
        'Cache-Control': 'no-store',
        Pragma: 'no-store',
        Expires: '0'
      }
      headers[tokenKey] = token

      const result = await axios({
        method: method.toUpperCase(),
        url: url,
        data: data,
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: headers,
        timeout: 600000, // 5초
        withCredentials: false
      })

      if (result.status !== 200) {
        return { code: result.status, msg: result.message }
      }

      if (result.data.code === 99999) {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({active: true, desc: result.data.msg, untilTime: new Date(result.data.untilTime)}))
        document.location.href='/maintenance'
        return { code: 0 }
      }

      return result.data
    } catch (e) {
      return { code: -1, msg: e }
    }
  }

  async _requestSendFile (method, url, data) {

    let host = process.env.VUE_APP_URL
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:32112/'
    } else {
      host = `https://backend.${host}/`
    }
     */

    if (host[host.length-1] !== '/') host += '/'
    url = host + url
    const tokenKey = `${process.env.VUE_APP_COOKIE_PREFIX}-Token`
    const token = store.getters['auth/token']

    try {
      const headers = {
        "Content-Type": "multipart/form-data",
      }
      headers[tokenKey] = token

      const result = await axios({
        method: method.toUpperCase(),
        url: url,
        data: data,
        headers: headers,
        timeout: 600000, // 5초
        withCredentials: false
      })

      if (result.status !== 200) {
        return { code: result.status, msg: result.message }
      }

      if (result.data.code === 99999) {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({active: true, desc: result.data.msg, untilTime: new Date(result.data.untilTime)}))
        document.location.href='/maintenance'
        return { code: 0 }
      }

      return result.data
    } catch (e) {
      return { code: -1, msg: e }
    }
  }

  async _requestDetail (method, url, data) {

    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:32112/'
    } else {
      host = `https://backend.${host}/`
    }
     */

    const tokenKey = `${process.env.VUE_APP_COOKIE_PREFIX}-Token`
    const token = store.getters['auth/token']

    try {
      const headers = {
        accept: 'application/json',
        'content-type': 'application/json',
        'Cache-Control': 'no-store',
        Pragma: 'no-store',
        Expires: '0',
        'secret-key': '!@#dkTkqkfl'
      }
      headers[tokenKey] = token

      const result = await axios({
        method: method.toUpperCase(),
        url: url,
        data: data,
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: headers,
        timeout: 600000, // 5초
        withCredentials: false
      })

      if (result.status !== 200) {
        return { code: result.status, msg: result.message }
      }

      if (result.data.code === 99999) {
        // 점검중 페이지로 이동
        window.localStorage.setItem('maintenance', JSON.stringify({active: true, desc: result.data.msg, untilTime: new Date(result.data.untilTime)}))
        document.location.href='/maintenance'
        return { code: 0 }
      }

      return result.data
    } catch (e) {
      return { code: -1, msg: e }
    }
  }

  async get(url, data) {
    return await this._request('GET', url, data)
  }

  async post(url, data) {
    return await this._request('POST', url, data)
  }

  async send(url, data) {
    return await this._requestSendFile('POST', url, data)
  }

  async postDetail(url, data) {
    return await this._requestDetail('POST', url, data)
  }
}

export default TAPIClient
