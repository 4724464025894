import APIClient from '@/libraries/api-client'
// import store from '../store'

class Transaction extends APIClient {

    async list(params = {}) {
        return await this.post('transaction', params)
    }

    async games(params = {}) {
        return await this.post('transaction/game', params)
    }

    async getPragmaticLink(transactionKey) {
        return await this.post('transaction/pragmatic', { transactionKey })
    }

    async getDetailLink(vendorkey, transactionKey) {
        return await this.post('transaction/getDetailLink', { transactionKey })
    }

    async getSlotDetailLink(vendorkey, transactionKey) {
        //return await this.post('transaction/getDetailLink', { transactionKey })
        let dUrl = 'https://' + vendorkey + '.uniongame.tech/api/detail'
        return await this.postDetail(dUrl, { transactionKey })
    }

    async getSeamlessList(transactionKey) {
        return await this.post('log/seamless', { search:{transactionKey} })
    }
}

export default Transaction
